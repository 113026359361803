<template>
  <a-card class="card" :bordered="false">
    <!-- 查询 -->
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="GLOBAL.queryRowGutter">
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="查询">
              <a-input v-model="queryParam.like_param" @keyup.enter.native="queryList" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <!-- 按钮 -->
    <div class="table-operator">
      <a-button type="query" icon="search" @click="queryList" style="margin-right: 10px">查询</a-button>
      <a-button type="primary" icon="plus" @click="handleCreate">新建</a-button>
    </div>

    <!-- 表格 -->
    <a-table
      size="middle"
      row-key="id"
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data-source="data"
      :pagination="pagination"
      @change="handleChangeTable"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="action" slot-scope="text, record, index">
        <template>
          <a @click="handleEdit(record, index)">编辑</a>
          <a-divider type="vertical" />
          <a @click="handledelete(record, index)">删除</a>
          <a-divider type="vertical" />
          <a @click="queryHistoryList(record)">查看历史汇率</a>
        </template>
      </span>
    </a-table>

    <!-- 新建 model -->
    <a-modal
      :visible="createVisible"
      :confirmLoading="createLoading"
      :width="800"
      :maskClosable="false"
      :title="createTitle"
      @ok="handleSubmit"
      @cancel="handleCancel"
    >
      <a-form-model
        layout="vertical"
        ref="createForm"
        :model="createForm"
        :rules="createRule"
      >
        <a-row :gutter="24">
          <a-col :span="8">
            <a-form-model-item label="币种类型" prop="currency_type">
              <a-input v-model="createForm.currency_type" placeholder="填写币种类型" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="简码" prop="short_code">
              <a-input v-model="createForm.short_code" placeholder="填写简码" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="汇率" prop="exchange_rate">
              <a-input v-model="createForm.exchange_rate" placeholder="填写汇率" />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-modal>

    <!-- 查看币种类型历史汇率信息 -->
    <a-modal
      :visible="historyVisible"
      :confirmLoading="historyLoading"
      :width="1500"
      :maskClosable="false"
      :title="historyCurrencyTypeTitle"
      :closable="false"
    >
      <template slot="footer">
        <a-button @click="historyCancel">关闭</a-button>
      </template>
      <div>
        <a-button type="primary" icon="plus" @click="handleCreateCurrencyTypeValidity">新建</a-button>
      </div>
      <a-table
        size="middle"
        row-key="id"
        :scroll="{ x: 'max-content' }"
        :columns="historyColumns"
        :data-source="historyData"
        :pagination="historyPagination"
        @change="historyHandleChangeTable"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <span slot="action" slot-scope="text, record">
          <template>
            <!-- <a @click="handleEditCurrencyTypeValidity(record)">编辑</a>
            <a-divider type="vertical" /> -->
            <a @click="handledeleteCurrencyTypeValidity(record)">删除</a>
          </template>
        </span>
      </a-table>
    </a-modal>

    <!-- 币种历史汇率新建 model -->
    <a-modal
      :visible="createCurrencyTypeValidityVisible"
      :confirmLoading="createCurrencyTypeValidityLoading"
      :width="800"
      :maskClosable="false"
      :title="createCurrencyTypeValidityTitle"
      @ok="handleCurrencyTypeValiditySubmit"
      @cancel="handleCurrencyTypeValidityCancel"
    >
      <a-form-model
        layout="vertical"
        ref="createCurrencyTypeValidityForm"
        :model="createCurrencyTypeValidityForm"
        :rules="createCurrencyTypeValidityRule"
      >
        <a-row :gutter="24">
          <a-col :span="8">
            <a-form-model-item label="汇率" prop="exchange_rate">
              <a-input v-model="createCurrencyTypeValidityForm.exchange_rate" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="有效期开始时间" prop="validity_begin_date">
              <a-date-picker v-model="createCurrencyTypeValidityForm.validity_begin_date" style="width: 100%" format="YYYY/MM/DD" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="有效期结束时间" prop="validity_end_date">
              <a-date-picker v-model="createCurrencyTypeValidityForm.validity_end_date" style="width: 100%" format="YYYY/MM/DD" />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-modal>
  </a-card>
</template>
<script>
import {
  pageCurrencyType,
  saveOrUpdateCurrencyType,
  deleteCurrencyType,
  pageCurrencyTypeValidity,
  saveOrUpdateCurrencyTypeValidity,
  deleteCurrencyTypeValidity
} from '@/api/common'
import moment from 'moment'

export default {
  name: 'CurrencyType',
  data() {
    return {
      queryParam: {},
      queryParam2: {},
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '币种类型',
          dataIndex: 'currency_type'
        },
        {
          title: '简码',
          dataIndex: 'short_code'
        },
        {
          title: '汇率',
          dataIndex: 'exchange_rate'
        },
        {
          title: '有效期开始时间',
          dataIndex: 'validity_begin_date'
        },
        {
          title: '有效期结束时间',
          dataIndex: 'validity_end_date'
        },
        {
          title: '操作',
          dataIndex: 'action',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      data: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '30', '50'],
        showSizeChanger: true,
        showTotal: (total, range) => `显示 ${range[0]} ~ ${range[1]} 条记录，共 ${total} 条记录`
      },
      createTitle: null,
      createVisible: false,
      createLoading: false,
      createForm: {
        currency_type: null,
        short_code: null,
        exchange_rate: null
      },
      createRule: {
        currency_type: [{ required: true, message: '请填写币种类型', trigger: 'blur' }],
        short_code: [{ required: true, message: '请填写币种简码', trigger: 'blur' }],
        exchange_rate: [{ required: true, message: '请填写币种汇率', trigger: 'blur' }]
      },
      historyVisible: false,
      historyLoading: false,
      historyCurrencyTypeTitle: null,
      historyColumns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '币种类型',
          dataIndex: 'currency_type_name'
        },
        {
          title: '简码',
          dataIndex: 'currency_type_short_code'
        },
        {
          title: '汇率',
          dataIndex: 'exchange_rate'
        },
        {
          title: '有效期开始时间',
          dataIndex: 'validity_begin_date',
          customRender: (text) => {
            return text ? moment(text).format('YYYY/MM/DD') : null
          }
        },
        {
          title: '有效期结束时间',
          dataIndex: 'validity_end_date',
          customRender: (text) => {
            return text ? moment(text).format('YYYY/MM/DD') : null
          }
        },
        {
          title: '操作',
          dataIndex: 'action',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      historyData: [],
      historyPagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '30', '50'],
        showSizeChanger: true,
        showTotal: (total, range) => `显示 ${range[0]} ~ ${range[1]} 条记录，共 ${total} 条记录`
      },
      createCurrencyTypeValidityVisible: false,
      createCurrencyTypeValidityLoading: false,
      createCurrencyTypeValidityTitle: null,
      createCurrencyTypeValidityForm: {
        currency_type_id: null,
        exchange_rate: null,
        validity_begin_date: null,
        validity_end_date: null
      },
      createCurrencyTypeValidityRule: {
        exchange_rate: [{ required: true, message: '请填写汇率', trigger: 'blur' }],
        validity_begin_date: [{ required: true, message: '请选择汇率有效期开始时间', trigger: 'blur' }],
        validity_end_date: [{ required: true, message: '请选择汇率有效期结束时间', trigger: 'blur' }]
      }
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      pageCurrencyType(this.queryParam).then(res => {
        this.pagination.total = res.total
        this.data = res.list
      })
    },
    handleChangeTable(page) {
      this.pagination.current = page.current
      this.pagination.pageSize = page.pageSize
      this.queryParam['page'] = page.current
      this.queryParam['page_size'] = page.pageSize
      this.loadData()
    },
    queryList() {
      this.pagination.current = 1
      this.pagination.pageSize = 10
      this.queryParam['page'] = 1
      this.queryParam['page_size'] = 10
      this.loadData()
    },
    handleCreate() {
      this.createVisible = true
      this.createTitle = '创建币种类型'
    },
    handleEdit(record) {
      this.createVisible = true
      this.createTitle = '修改币种类型'
      this.createForm = Object.assign({}, record)
    },
    handleSubmit() {
      this.createLoading = true
      this.$refs.createForm.validate(valid => {
        if (valid) {
          saveOrUpdateCurrencyType(this.createForm).then(res => {
            this.$notification['success']({
              message: '提示',
              description: '操作成功'
            })
          }).finally(_ => {
            this.handleCancel()
            this.loadData()
          })
        } else {
          this.createLoading = false
        }
      })
    },
    handleCancel() {
      this.createLoading = false
      this.createVisible = false
      this.createForm = {
        currency_type: null,
        short_code: null,
        exchange_rate: null
      }
    },
    handledelete(record, index) {
      this.$confirm({
        title: '确认提示',
        content: '确定要删除？',
        onOk: () => {
          deleteCurrencyType({ ids: [record.id] }).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: '删除成功.'
            })
            this.loadData()
          })
        }
      })
    },
    queryHistoryList(record) {
      this.queryParam2['currency_type_id'] = record.id
      this.historyVisible = true
      this.historyCurrencyTypeTitle = `${record.currency_type}历史汇率明细`
      this.loadCurrencyTypeValidity()
    },
    loadCurrencyTypeValidity() {
      pageCurrencyTypeValidity(this.queryParam2).then(res => {
        this.historyPagination.total = res.total
        this.historyData = res.list
      })
    },
    historyCancel() {
      this.historyVisible = false
      this.historyData = []
      // 关闭查看历史汇率窗口后、重新执行一下主页的加载方法
      this.loadData()
    },
    historyHandleChangeTable(page) {
      this.historyPagination.current = page.current
      this.historyPagination.pageSize = page.pageSize
      this.queryParam2['page'] = page.current
      this.queryParam2['page_size'] = page.pageSize
      this.loadCurrencyTypeValidity()
    },
    handleCreateCurrencyTypeValidity() {
      this.createCurrencyTypeValidityVisible = true
      this.createCurrencyTypeValidityTitle = '创建币种汇率有效期'
    },
    handleEditCurrencyTypeValidity(record) {
      this.createCurrencyTypeValidityVisible = true
      this.createCurrencyTypeValidityTitle = '修改币种汇率有效期'
      this.createCurrencyTypeValidityForm = Object.assign({}, record)
    },
    handleCurrencyTypeValiditySubmit() {
      this.createCurrencyTypeValidityLoading = true
      this.$refs.createCurrencyTypeValidityForm.validate(valid => {
        if (valid) {
          const reqParam = {
            ...this.createCurrencyTypeValidityForm,
            currency_type_id: this.queryParam2['currency_type_id'],
            validity_begin_date: this.createCurrencyTypeValidityForm.validity_begin_date ? moment(this.createCurrencyTypeValidityForm.validity_begin_date).startOf('day').valueOf() : null,
            validity_end_date: this.createCurrencyTypeValidityForm.validity_end_date ? moment(this.createCurrencyTypeValidityForm.validity_end_date).endOf('day').valueOf() : null
          }
          saveOrUpdateCurrencyTypeValidity(reqParam).then(res => {
            this.$notification['success']({
              message: '提示',
              description: '操作成功'
            })
          }).finally(_ => {
            this.handleCurrencyTypeValidityCancel()
            this.loadCurrencyTypeValidity()
          })
        } else {
          this.createCurrencyTypeValidityLoading = false
        }
      })
    },
    handleCurrencyTypeValidityCancel() {
      this.createCurrencyTypeValidityVisible = false
      this.createCurrencyTypeValidityLoading = false
      this.createCurrencyTypeValidityForm = {
        currency_type_id: null,
        exchange_rate: null,
        validity_begin_date: null,
        validity_end_date: null
      }
    },
    handledeleteCurrencyTypeValidity(record) {
      this.$confirm({
        title: '确认提示',
        content: '确定要删除？',
        onOk: () => {
          deleteCurrencyTypeValidity({ ids: [record.id] }).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: '删除成功.'
            })
            this.loadCurrencyTypeValidity()
          })
        }
      })
    }
  }
}
</script>
